import React from "react";

export default class ExperienceModal extends React.Component {

  render() {
    return (
        <>
          <button type="button" className="btn btn-warning"
                  data-toggle="modal"
                  data-target={`#${this.props.id}`}>
            Read More
          </button>
          <div className="modal fade" id={this.props.id} tabIndex="-1"
               role="dialog" aria-labelledby="exampleModalScrollableTitle"
               aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="mb-0">
                    {this.props.title}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal"
                          aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h4>{this.props.title}</h4>
                  {this.props.modalBodyContent}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary"
                          data-dismiss="modal">Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
    )
  }
}
