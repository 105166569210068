import React from 'react';
import {Reveal, Zoom} from "react-reveal";
import {connect} from "react-redux";
import HeaderLinks from "./HeaderLinks";

class HeaderComponent extends React.Component {

    render() {
        return (
            <div id="header-wrapper" className="row">
                <div id="header" className="container">
                    <nav className="navbar fixed-top navbar-expand-lg" id="home">
                        <div className="container">
                            <Reveal effect="fadeInUp">
                                <div id="my-nav"
                                     className="collapse navbar-collapse shift-right">
                                    <ul className="navbar-nav menu-links">
                                        {this.props.headerLinks.map((headerLink, index) =>
                                            <HeaderLinks
                                                key={index}
                                                headerLink={headerLink}
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Reveal>
                        </div>
                    </nav>

                    <div id="logo" className="header-text">
                        <Zoom left cascade>
                            <div className="header-font">Make something</div>
                        </Zoom>
                        <Zoom right cascade>
                            <div className="header-font"> you Love</div>
                        </Zoom>
                    </div>
                </div>
            </div>
        )
    }
}

const stateToPropertyMapper = (state) => {
    return {
        headerLinks: state.headerReducer.headerLinks
    }
};

export default connect(stateToPropertyMapper)(HeaderComponent)