import React from "react";
import "./experience-styles.css";
import tcsLogo from "../../images/tcs.jpg";
import tlfLogo from "../../images/tlf.png";
import tptLogo from "../../images/thirdpole.png";
import analytext from "../../images/workex/analytext.png"
import wanderlust from "../../images/wanderlust.jpg";
import pg from "../../images/pg.png";
import {Reveal} from "react-reveal";
import ExperienceModal from "./ExperienceModal";
import {TataModalContent} from "./modal_content/TataModalContent";
import {LightFactoryContent} from "./modal_content/LightFactoryContent";
import {TravelOPortContent} from "./modal_content/TravelOPortContent";
import {TPTContent} from "./modal_content/TPTContent";
import {AnalytextContent} from "./modal_content/AnalytextContnt";
import {PrimeGuardianContent} from "./modal_content/PrimeGuardianContent";
import Typewriter from "typewriter-effect";
import ScrollTrigger from "react-scroll-trigger";

class ExperienceComponent extends React.Component {
    state = {
        showTitle: false
    }

    onEnterViewport = () => this.setState({showTitle: true})

    render() {
        return <ScrollTrigger onEnter={this.onEnterViewport}>
            <div className="row">
                <div className="container-fluid work" id="work">
                    <div className="container">
                        {this.state.showTitle ? <h2 className="hd-48 text-center mb-5"><Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(100)
                                    .typeString("Professional Experience")
                                    .start();
                            }}
                        /></h2> : <></>}
                        <Reveal effect="fadeInUp">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={analytext} className="card-img-top greyscale"
                                             alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://www.analytext.com/"
                                               target="_blank"
                                               rel="noopener noreferrer">Analytext <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text">Built a tool to enable researchers without
                                                programming skills conduct research using textual analysis.</p>
                                            <ExperienceModal
                                                id="analytext"
                                                title='Analytext'
                                                modalBodyContent={AnalytextContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={tptLogo} className="card-img-top greyscale"
                                             alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://www.pole3.com"
                                               target="_blank"
                                               rel="noopener noreferrer">Third Pole Therapeutics <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text">I have worked at Third Pole Therapeutics in
                                                Waltham,
                                                Boston, as a Software Developer Co-op for 6 months.</p>
                                            <ExperienceModal
                                                id="thirdPoleTherapeutics"
                                                title='Third Pole Therapeutics'
                                                modalBodyContent={TPTContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={pg} className="card-img-top greyscale"
                                             alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://primeguardian.co/"
                                               target="_blank"
                                               rel="noopener noreferrer">Prime Guardian <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text">Built the company website for Prime Guardian -
                                                A
                                                cyber
                                                security company using ReactJS.</p>
                                            <ExperienceModal
                                                id="primeGuardian"
                                                title='Prime Guardian'
                                                modalBodyContent={PrimeGuardianContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={tcsLogo} className="card-img-top greyscale"
                                             alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://tcs.com"
                                               target="_blank"
                                               rel="noopener noreferrer">Tata Consultancy Services <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text">I have worked at Tata Consultancy
                                                Services in India, as a Full Stack Developer for 2 years and 8
                                                months.</p>
                                            <ExperienceModal
                                                id="tataConsultancyService"
                                                title='Tata Consultancy Services'
                                                modalBodyContent={TataModalContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={tlfLogo} className="card-img-top greyscale" alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://thelightfactory.herokuapp.com"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >The Light Factory <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text">This was my second responsive
                                                Photography
                                                website which was developed using Javascript,
                                                Bootstrap.</p>
                                            <ExperienceModal
                                                id="theLightFactory"
                                                title='The Light Factory'
                                                modalBodyContent={LightFactoryContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img src={wanderlust} className="card-img-top greyscale"
                                             alt="..."/>&nbsp;
                                        <div className="card-body">
                                            <a className="card-title-font"
                                               href="https://traveloport.herokuapp.com"
                                               target="_blank"
                                               rel="noopener noreferrer">TravelOPort <i
                                                style={{fontSize: '16px'}}
                                                className="fas fa-sm fa-external-link-alt"/></a>
                                            <hr/>
                                            <p className="card-text" align="justify"> Designed a web
                                                application using ReactJS, Node, Express and Mongodb that
                                                can be used to search/book
                                                flights, hotels.</p>
                                            <ExperienceModal
                                                id="travelOPort"
                                                title='TravelOPort - To Travel is to Live'
                                                modalBodyContent={TravelOPortContent()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </ScrollTrigger>
    }
}


export default ExperienceComponent
