import React from "react";
import "./contact-styles.css"
import {Reveal} from "react-reveal";
import Typewriter from "typewriter-effect";
import ScrollTrigger from "react-scroll-trigger";

class ContactComponent extends React.Component {
    state = {
        showTitle: false
    }

    onEnterViewport = () => this.setState({showTitle: true})

    render() {
        return (
            <ScrollTrigger onEnter={this.onEnterViewport}>
                <div className="container-fluid contact" id="contact">
                    <div className="container">
                        {this.state.showTitle ? <h2 className="hd-48 text-center mb-5"><Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(300)
                                    .typeString("Contact")
                                    .start();
                            }}
                        /></h2> : <></>}
                        <Reveal effect="fadeInUp">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="cnt-box pb-5">
                                        <i className="far fa-address-card"/>
                                        <span> Address </span><br/>
                                        <a href="http://maps.google.com/?q=Boston" target="_blank"
                                           rel="noopener noreferrer">Boston, MA</a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="cnt-box pb-5">
                                        <i className="far fa-envelope"/>
                                        <span> Email </span><br/>
                                        <a href="mailto:amit.madgi@gmail.com">amit.madgi@gmail.com</a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="cnt-box">
                                        <i className="fas fa-phone"/>
                                        <span> Phone </span>
                                        <p>+1 (857) 210 9207</p>
                                    </div>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </ScrollTrigger>
        )
    }
}

export default ContactComponent