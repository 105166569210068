import React from "react";

export const AnalytextContent = () => <>
    <p>
        <span>Duration : </span>Jan 2021 - Present<br/>
        <span>Technology Stack : </span>Python, Flask, React, PostgreSQL<br/>
        <span>Tools : </span>Git, AWS Amplify, Elasticbeanstalk<br/>
    </p>
    <hr/>
    <span>Project Description : </span><br/>
    I have been collaborating with researchers from <b>Northeastern University</b> and <b>Bentley University</b> to
    build a full stack application using React, Flask and PostgreSQL. The website provides a platform
    intended to serve the academic community and enable researchers without programming skills to conduct research using
    textual analysis.<br/>
    <span>Responsibilities : </span><br/>
    <ul>
        <li>
            Collaborating with researchers to assimilate product
            requirements and create a full stack application to deliver NLP
            procedures using <b>Python, React,
            Flask</b> and <b>PostgreSQL</b> for development.
        </li>
        <li>
            Utilizing <b>AWS Elastic Beanstalk</b> for Back-end, <b>AWS
            Amplify</b> for
            front-end deployment, <b>AWS Route 53</b> for domain management
            and <b>AWS
            Certificate Manager</b> to provide SSL certificates for secure
            communication.
        </li>
    </ul>
</>

