import React, {useState} from "react";
import Typewriter from "typewriter-effect";
import {Fade} from "react-reveal";
import ScrollTrigger from "react-scroll-trigger";

const AcademicProjectsComponent = () => {
    const [showTitle, setShowTitle] = useState(false)

    const onEnterViewport = () => setShowTitle(true)
    return (
        <ScrollTrigger onEnter={onEnterViewport}>
            <div className="row" id="academics" data-section="academics">
                <div className="container-fluid academics" id="academics">
                    <div className="container">
                        {showTitle ? <h2 className="hd-48 text-center mb-5"><Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(100)
                                    .typeString("Academics & Projects")
                                    .start();
                            }}
                        /></h2> : <></>}
                        <div id="fh5co-resume" className="fh5co-bg-color">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-md-offset-0">
                                        <ul className="timeline">
                                            <li className="timeline-heading text-center animate-box">
                                                <div><h3>Work Experience</h3></div>
                                            </li>
                                            <Fade left>
                                                <li className="animate-box timeline-unverted">
                                                    <div className="timeline-badge"><i className="fas fa-briefcase"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Research Assistant</h3>
                                                            <span
                                                                className="company">Northeastern University - Jan 2021 - Current</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>
                                                                    Collaborating with researchers to assimilate product
                                                                    requirements and create a full stack application to
                                                                    deliver
                                                                    NLP
                                                                    procedures using <b>Python, React,
                                                                    Flask</b> and <b>PostgreSQL</b> for development.
                                                                </li>
                                                                <br/>
                                                                <li>
                                                                    Utilizing <b>AWS Elastic Beanstalk</b> for
                                                                    Back-end, <b>AWS
                                                                    Amplify</b> for
                                                                    front-end deployment, <b>AWS Route 53</b> for domain
                                                                    management
                                                                    and <b>AWS
                                                                    Certificate Manager</b> to provide SSL certificates
                                                                    for
                                                                    secure
                                                                    communication.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade right>
                                                <li className="timeline-inverted animate-box">
                                                    <div className="timeline-badge"><i className="fas fa-briefcase"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Graduate Teaching Assistant -
                                                                Data
                                                                Structures & Algorithms</h3>
                                                            <span
                                                                className="company">Northeastern University - Jan 2021 - Current</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>Mentoring a class of 20 students helping them better
                                                                    understand
                                                                    the
                                                                    concepts of <b>data structures, algorithms, and
                                                                        their
                                                                        applications </b>
                                                                    within computer systems by providing meaningful
                                                                    feedbacks
                                                                    besides
                                                                    grading the assignments.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade left>
                                                <li className="animate-box timeline-unverted">
                                                    <div className="timeline-badge"><i className="fas fa-briefcase"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Graduate Teaching Assistant -
                                                                Web
                                                                Development</h3>
                                                            <span
                                                                className="company">Northeastern University - May 2020 - Jul 2020</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>Mentored a class of 58 helping them understand the
                                                                    concepts
                                                                    of
                                                                    ES6,
                                                                    ReactJS, Angular, Node, JPA, SQL, MongoDB, and Redux
                                                                    by
                                                                    providing
                                                                    meaningful feedbacks besides grading the assignments
                                                                    and
                                                                    projects.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <br/>
                                            <li className="timeline-heading text-center animate-box">
                                                <div><h3>Projects</h3></div>
                                            </li>
                                            <Fade right>
                                                <li className="timeline-inverted animate-box">
                                                    <div className="timeline-badge"><i className="fa fa-graduation-cap"
                                                                                       aria-hidden="true"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Distributed Topical Web
                                                                Crawler</h3>
                                                            <span
                                                                className="company">Northeastern University - Mar 2021</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>
                                                                    Implemented a web crawler using <b>Python</b> to
                                                                    scrape
                                                                    65000
                                                                    documents for Nuclear Attacks & Sites ensuring
                                                                    politeness
                                                                    policy.
                                                                </li>
                                                                <li>
                                                                    Indexed text data of scrapped pages along with <b>link
                                                                    graphs</b> to <b>Elasticsearch.</b>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade left>
                                                <li className="timeline-unverted animate-box">
                                                    <div className="timeline-badge"><i className="fa fa-graduation-cap"
                                                                                       aria-hidden="true"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Prattle - Chat
                                                                Application</h3>
                                                            <span
                                                                className="company">Northeastern University - Jun 2020</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>
                                                                    Conceptualized and implemented a robust new-age chat
                                                                    application
                                                                    using <b>React, Java, MongoDB, WebSockets, AWS
                                                                    S3.</b>
                                                                </li>
                                                                <li>
                                                                    Implemented features such as group-messaging,
                                                                    threads, media
                                                                    upload and government taping.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade right>
                                                <li className="animate-box timeline-inverted">
                                                    <div className="timeline-badge"><i className="fa fa-graduation-cap"
                                                                                       aria-hidden="true"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">
                                                                <a href='https://travel-o-port.web.app/'
                                                                   style={{textDecoration: 'none'}}
                                                                   target='_blank'
                                                                   rel='noopener noreferrer'>Travel-O-Port <i
                                                                    style={{fontSize: '18px'}}
                                                                    className="fas fa-sm fa-external-link-alt"/></a>
                                                            </h3>
                                                            <span
                                                                className="company">Northeastern University - Apr 2020</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>
                                                                    Designed a <b>web application</b> using <b>MERN
                                                                    Stack</b>,
                                                                    used
                                                                    to
                                                                    search/book
                                                                    flights and hotels retrieved from Amadeus API.
                                                                </li>
                                                                <li>
                                                                    Implemented user management and password encryption
                                                                    using
                                                                    <b> crypto-js</b>, converted into hash code before
                                                                    storing
                                                                    it in
                                                                    the
                                                                    mongo collection.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade left>
                                                <li className="timeline-unverted animate-box">
                                                    <div className="timeline-badge"><i className="fa fa-graduation-cap"
                                                                                       aria-hidden="true"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Whiteboard Application</h3>
                                                            <span
                                                                className="company">Northeastern University - Mar 2020</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>
                                                                    A multipurpose training application built
                                                                    specifically for
                                                                    teachers, enabling them to create complete courses
                                                                    for
                                                                    students
                                                                    built using <b>React, Java, and SQL</b>
                                                                </li>
                                                                <li>
                                                                    The app allows its user to <b>CRUD</b> courses, that
                                                                    contain
                                                                    modules,
                                                                    and each module he/she can create lessons under each
                                                                    module.
                                                                    The
                                                                    lessons can have several topics under them which
                                                                    contain
                                                                    widgets
                                                                    such as paragraph, lists, images, and headings.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                            <Fade right>
                                                <li className="animate-box timeline-inverted">
                                                    <div className="timeline-badge"><i className="fa fa-graduation-cap"
                                                                                       aria-hidden="true"/>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-heading">
                                                            <h3 className="timeline-title">Image Processing
                                                                Application</h3>
                                                            <span
                                                                className="company">Northeastern University - Dec 2019</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <ul className='p-0'
                                                                style={{listStyleType: 'none', fontSize: '17px'}}>
                                                                <li>Engineered an image processing application with an
                                                                    interactive
                                                                    GUI
                                                                    using <b>Java, Java Swing</b> to perform greyscale,
                                                                    mosaic,
                                                                    sepia,
                                                                    blur,
                                                                    etc. supporting batch processing of commands
                                                                    following
                                                                    the <b>Command
                                                                        Design Pattern.</b></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Fade>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollTrigger>)
}

export default AcademicProjectsComponent