import React from "react";

export const TravelOPortContent = () =>
    <>
    <p>
      <span>Duration : </span>Mar 2020 – Apr 2020<br/>
      <span>Technology Stack : </span>ReactJS, Node, Express, MongoDB<br/>
      <span>Tools : </span>Git, IntelliJ, Heroku, M-Lab, Amadeus API<br/>
      <span>Visit : </span>
      <a href="https://traveloport.herokuapp.com/"
         rel="noopener noreferrer"
         target="_blank"> TravelOPort
      </a>
    </p>
      <hr/>
      <span>Project Description : </span><br/>
      TravelOPort is a one-stop to plan your trip.<br/>
      <ul>
        <li>A user can search for flights and get the latest flight details such
          as the departure and arrival times, fares, and allow the user to book
          a particular flight either for himself or others.
        </li>
        <li>Not only the website allows you to book flights but also one can
          search for hotels and look for currency conversion rates.
        </li>
        <li>A registered user can post feedback or grievances from their profile
          section which can be viewed under the feedback section from the Admin
          page.
        </li>
        <li>We have used <span>CryptoJS</span> for encryption of passwords on
          the client-side
          before sending it over to the API.
        </li>
        <li>At the API layer, the password is decrypted and converted into hash
          before it is stored into the database.
        </li>
        <li>The admin has a dedicated page where he can view details and modify
          the user details.
        </li>
        <li>This Web Application was developed using ReactJS in front-end with
          Node.js as the Middle tier and MongoDB in the back-end.
        </li>
        <li>We have used <span>Amadeus API</span> for fetching flight details
          and hotel
          details.
        </li>
        <li>The other CRUD operations were done by implementing RESTful API.
        </li>
      </ul>
    </>
