import React, {useState} from "react";
import quote from "../../images/quote.png"
import brian from '../../images/reccomend/brian.jpg'
import janet from '../../images/reccomend/janet.png'
import jugal from '../../images/reccomend/jugal.png'
import "./reccomendation-styles.css"
import {Reveal} from "react-reveal";
import Typewriter from "typewriter-effect";
import ScrollTrigger from "react-scroll-trigger";

const RecommendationComponent = () => {
    const [showTitle, setShowTitle] = useState(false)

    const onEnterViewport = () => setShowTitle(true)
    return (
        <ScrollTrigger onEnter={onEnterViewport}>

            <div className="row">


                <div className="container-fluid recommends" id="recommends">
                    <div className="container">
                        {showTitle ? <h2 className="hd-48 text-center mb-5"><Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(200)
                                    .typeString("What Others Say")
                                    .start();
                            }}
                        /></h2> : <></>}
                        <Reveal effect="fadeInUp">
                            <div id="carouselExampleIndicators" className="carousel slide"
                                 data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0"
                                        className="active"/>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"/>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-interval="2000">
                                        <div className="col-lg-12">
                                            <div className="quote-icon d-none d-md-block">
                                                <img src={quote} alt="quote icon"/>
                                            </div>
                                            <div className="quote-content">

                                                <p className="text-justify">
                                                    <i className="fas fa-quote-left"/> Amit worked under me for the
                                                    second half
                                                    of 2020. Under challenging remote
                                                    work conditions and ill-defined requirements, Amit was able to learn
                                                    a new
                                                    IoT protocol and web framework to deliver incredible value to the
                                                    organization. Amit is diligent, hard working, and understands
                                                    software
                                                    development well. At the end of his internship he demonstrated his
                                                    work to
                                                    the marketing organization, and they were extremely impressed at the
                                                    quality
                                                    of work, utility of features developed, and the speed at which he
                                                    was able
                                                    to complete the work. I highly recommend Amit. <i
                                                    className="fas fa-quote-right"/>
                                                </p>
                                                <div>
                                                    <img className='r-image' src={brian} alt={'Could not load'}/>
                                                    <span style={{display: "inline-block"}}>
                                                <p><b>Brian Schick</b><br/>Head of Software at Third Pole Therapeutics</p></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-interval="2000">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="quote-icon d-none d-md-block">
                                                    <img src={quote} alt="quote icon"/>
                                                </div>
                                                <div className="quote-content">
                                                    <p className="text-justify"><i className="fas fa-quote-left"/> Amit
                                                        worked
                                                        for me as a software developer intern. He was able to come up to
                                                        speed
                                                        very quickly, learning the tools and technology. Due to his
                                                        agile nature
                                                        and eagerness to learn he was a strong contributor to our
                                                        brainstorming
                                                        sessions. Amit is a great team player with the capability to
                                                        investigate
                                                        issues on his own to provide answers. He has great technical
                                                        skills that
                                                        were evident when he presented a demo of his work that impressed
                                                        us all!
                                                        <i className="fas fa-quote-right"/></p>
                                                    <div>
                                                        <img className='r-image' src={janet} alt={'Could not load'}/>
                                                        <span style={{display: "inline-block"}}>
                                                <p><b>Janet Nicholson</b><br/>Principal Software Engineer at Third Pole Therapeutics</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-interval="2000">
                                        <div className="col-lg-12">
                                            <div className="quote-icon d-none d-md-block">
                                                <img src={quote} alt="quote icon"/>
                                            </div>
                                            <div className="quote-content">
                                                <p className="text-justify"><i className="fas fa-quote-left"/> I came
                                                    across
                                                    Amit in my first semester as a bright
                                                    and diligent student. His fundamentals about anything he
                                                    did were crystal clear. I got a chance to work with him
                                                    during CS5610 Web Development and he was no more than a
                                                    wizard when it came to front end. Just like a wizard
                                                    with his wand, Amit with his laptop would make magical
                                                    pages out of thin air. UI/UX and designing would come
                                                    natural to him. Whatever you throw at him, he would find
                                                    a solution, and experiencing this in person was kind of
                                                    surreal. Amit has also proven leadership skills, not
                                                    only he managed to deal with heavy coursework, he also
                                                    led a team of 3 from designing to developing a full
                                                    stack web application. And I'd gladly say that working
                                                    with him has motivated me further to strive like
                                                    him. <i className="fas fa-quote-right"/>
                                                </p>
                                                <div>
                                                    <img className='r-image' src={jugal} alt={'Could not load'}/>
                                                    <span style={{display: "inline-block"}}>
                                                <p><b>Jugal Joshi</b><br/>CS Grad at Northeastern University</p></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev"
                                   href="#carouselExampleIndicators"
                                   role="button" data-slide="prev">
            <span className="carousel-control-prev-icon"
                  aria-hidden="true"/>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next"
                                   href="#carouselExampleIndicators"
                                   role="button" data-slide="next">
            <span className="carousel-control-next-icon"
                  aria-hidden="true"/>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </Reveal>
                    </div>
                </div>

            </div>
        </ScrollTrigger>)
}

export default RecommendationComponent
