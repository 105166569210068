import React, {useState} from "react";
import neuLogo from "../../images/NEU-logo.png";
import muLogo from "../../images/MU.jpg";
import {Reveal} from "react-reveal";
import Typewriter from "typewriter-effect";
import ScrollTrigger from "react-scroll-trigger";

const EducationComponent = () => {
    const [showTitle, setShowTitle] = useState(false)

    const onEnterViewport = () => setShowTitle(true)
    return (
        <ScrollTrigger onEnter={onEnterViewport}>
            <div className="row" id="education" data-section="education">
                <div className="container-fluid skills" id="skills">
                    <div className="container">
                        {showTitle ? <h2 className="hd-48 text-center mb-5"><Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(100)
                                    .typeString("Education")
                                    .start();
                            }}
                        /></h2> : <></>}
                        <div className="row ml-0 mr-0">
                            <div className="col-lg-12">
                                <Reveal effect="fadeInUp">
                                    <div className="media">
                                        <img src={neuLogo} className="neu-logo" alt="logo"/>&nbsp;
                                        <div className="media-body">
                                            <h3 className="mt-0">Northeastern University</h3>
                                            <h6>Master's in Computer Science (2021)</h6>
                                            <p>Pursuing master's in Computer Science with a current GPA
                                                of <span>3.5</span><br/>
                                                <span className="font-italic">Relevant Courses : </span>Human Computer
                                                Interaction | Program Design Paradigm | Algorithms | Web Development |
                                                Foundation of Software Engineering | Artificial Intelligence |
                                                Information
                                                Retrieval
                                            </p>
                                        </div>
                                    </div>
                                </Reveal>
                            </div>
                            &nbsp;
                            <div className="col-lg-12">
                                <Reveal effect="fadeInUp">
                                    <div className="media">
                                        <img src={muLogo} className="mu-logo" alt="logo"/>&nbsp;
                                        <div className="media-body">
                                            <h3 className="mt-0">University of Mumbai</h3>
                                            <h6>Bachelor of Engineering (2016)</h6>
                                            <p>Completed undergraduate from University of Mumbai in
                                                Electronics Engineering with <span>3.4 GPA</span><br></br>
                                                <span className="font-italic">Relevant Courses : </span>Object Oriented
                                                Programming | Data Structures</p>
                                        </div>
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollTrigger>)
}

export default EducationComponent
