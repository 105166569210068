import React from "react";

export const TataModalContent = () =>
    <>
        <p>
            <span>Account Name : </span>CISCO<br/>
            <span>Duration : </span>Dec 2016 - Aug 2019<br/>
            <span>Technology Stack : </span>Angular, Java, Javascript, HTML, CSS, MongoDB, SQL, jQuery, AJAX<br/>
            <span>Tools : </span>Git, IntelliJ, Kibana, Jenkins, Splunk, Elastic Search<br/>
        </p>
        <hr/>
        <p>
            <span>Project Description : </span><br/>
            The purpose of CISCO Ordering
            tool was to be used by it's clients for ordering purpose. The
            technologies used in the application were <span>JavaScript, JQuery,
                AJAX, JAVA, Spring, SQL, MongoDB,</span> and <span>Angular JS.</span> Apart
            from the
            programming languages
            we also used tools like <span>Git, Kibana, Splunk, Elastic Search,
                Jenkins, IBM Udeploy, IBM URelease</span> for
            debugging production issues and deployments.<br/>
            <span>Responsibilities : </span><br/>
        </p>
            <ul>
                <li>
                    Worked in an <span>agile environment,</span> as part of the
                    offshore team
                    and collaborated with the on-site team
                    regularly for code implementation and deployments.
                </li>
                <li>
                    Developed functionality
                    using <span>Javascript and Java</span> for
                    downloading order details fetched from the service in excel
                    and pdf format.
                </li>
                <li>
                    <span>Redesigned</span> the existing user interface in CISCO
                    using JQuery
                    templates, achieving faster load time and ensuring code
                    reusability.
                </li>
                <li>
                    <span>Developed</span> web pages using HTML, CSS, and
                    JavaScript, taking
                    advantage of the Angular.JS framework and JAVA.
                </li>
                <li>
                    Designed several <span>responsive modals</span> in the
                    application using
                    HTML, CSS, Javascript and jQuery for better user
                    experience.
                </li>
                <li>
                    Utilized frameworks such
                    as <span>Struts and Spring MVC</span> for
                    persistence and application layers.
                </li>
                <li>
                    Efficiently worked within the team in an <span>AGILE environment,</span>
                    which migrated from SQL database to MongoDB which cut
                    overall data read and write time by 48% with a 15% increase
                    in
                    customer satisfaction.
                </li>
                <li>
                    Performed
                    complete <span>application life cycle functions</span> from
                    conception to final release to users including
                    Design/Analysis, Implementation, Testing, Deployment, and
                    Maintenance of the assigned scopes on production.
                </li>
                <li>
                    POC
                    for <span>Partner On-boarding Environment,</span> managed
                    the
                    production environment used by <span>clients</span> for
                    training
                    purposes.
                </li>
                <li>
                    Built deployment plans for QA, release candidate and final
                    release builds using <span>Jenkins, IBM uRelease and uDeploy.</span>
                </li>
                <li>
                    <span>Debugged production issues</span> and stage issues
                    using tools like
                    Kibana, Splunk and CAE/LAE logs. <span>Monitored production
                    environment</span> and provided support after every
                    production
                    deployment.
                </li>
            </ul>
    </>
