import React, {useState} from "react";
import "./about.css"
import logo from "../../images/amit.jpg"
import {Fade, Reveal} from "react-reveal";
import Typewriter from "typewriter-effect";
import {applyPolyfills, defineCustomElements} from "@uit/glitch-image/loader";
import ScrollTrigger from "react-scroll-trigger";

applyPolyfills().then(() => {
    defineCustomElements();
});

const AboutComponent = () => {
    const [showTitle, setShowTitle] = useState(false)

    const onEnterViewport = () => setShowTitle(true)
    return (
        <ScrollTrigger onEnter={onEnterViewport}>
            <div className="row" id="about">
                <div className="container-fluid about" id="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="about-img">
                                    <div className="for-box"/>
                                    <glitch-image src={logo} alt="about leader"
                                                  className="w-100"/>
                                </div>
                            </div>
                            <div className="col-lg-7 pl-5">
                                <Fade left>
                                    <h2>About</h2>
                                </Fade>
                                {showTitle ? <h3>
                                    <Typewriter
                                        onInit={(typewriter) => {
                                            typewriter.pauseFor(1000)
                                                .typeString("Hello! I'm Amit Madgi")
                                                .start();
                                        }}
                                    /></h3> : <></>}

                                <Reveal effect="fadeInUp">
                                    <h5 className="text-justify">Apart from coding and
                                        creating beautiful user interfaces, I
                                        love photographing wildlife and playing soccer. I believe
                                        strongly in the value of sharing knowledge and learning from
                                        my peers. We’re all achievers on the inside. Sharing knowledge
                                        practices pushes you to become better at what you do while
                                        driving you at the same time to contribute with your own
                                        insights.</h5>
                                    <br/><br/>
                                    <div>
                                        <h5>
                                            <i className="fas fa-quote-left fa-xs"/> People don't care about what you
                                            say,
                                            they
                                            care about what you build. - Mark Zuckerberg <i
                                            className="fas fa-quote-right fa-xs"/></h5>
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="/resume.pdf"
               className="float d-none d-sm-block"
               download={'Resume-Amit-Madgi.pdf'}
               >
                <i className="fas fa-cloud-download-alt my-float fa-2x"/>
            </a>
        </ScrollTrigger>);
}

export default AboutComponent
