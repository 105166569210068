const state = {
  skills: [
    {
      title: "Angular",
      logoName: "angular.png",
      content: `Sifting through teaspoons of clay and sand scraped from
                        the
                        floors of caves, German researchers have managed to
                        isolate
                        ancient human.`
    }
  ]
};

const skillsReducer = () => {
  return state;
};

export default skillsReducer