import React from "react";

export const PrimeGuardianContent = () =>
    <>
        <p>
            <span>Duration : </span>2021<br/>
            <span>Technology Stack : </span>HTML, CSS, Javascript, ReactJS,
            Bootstrap<br/>
            <span>Visit : </span>
            <a href="https://primeguardian.co/"
               target="_blank"
               rel="noopener noreferrer"
            > Prime Guardian
            </a>
        </p>
        <ul>
            <li>
                Prime Guardian is a provider of cyber
                security services for rapidly growing Banking, Financial and IT industries.<br/>
                I built the company's website and still am responsible for on going support and development changes.
            </li>
        </ul>
    </>
