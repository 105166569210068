import React from "react"
import {Link} from "react-scroll";

const HeaderLinks = ({headerLink}) =>

    <li key={headerLink.id} className="nav-item">
      <Link
          className={headerLink.className}
          to={headerLink.to}
          spy={headerLink.spy}
          smooth={headerLink.smooth}
          offset={headerLink.offset}
          duration={headerLink.duration}>
        {headerLink.title}
      </Link>
    </li>;

export default HeaderLinks

