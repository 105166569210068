import React from "react";

export const TPTContent = () => <>
    <p>
        <span>Duration : </span>Aug 2020 - Jan 2021<br/>
        <span>Technology Stack : </span>Flutter, Dart, AWS, MQTT<br/>
        <span>Tools : </span>Git, JIRA<br/>
    </p>
    <hr/>
    <span>Project Description : </span><br/>
    I was hired to build an application for a medical device to enable doctors to visualize and analyze patient
    vitals. We used <span>JIRA</span> for task tracking and collaboration. <span>Git</span> has and always will be
    the most favorite code collaborator to be used. Although I was very familiar and fluent
    with <span>React </span> I chose to build the application using <span>Flutter and Dart</span> since flutter
    us to build native android and ios apps using single codebase.<br/>
    Flutter is an open-source UI software development kit created by Google. It is used to develop applications for
    Android, iOS, Linux, Mac, Windows, Google Fuchsia, and the web from a single codebase. <br/>
    I had designed the application in such a way that it was beautiful and adaptable both on the windows as well as
    an android device.<br/>
    My decision was well appreciated at the end of co-op presentation by my managers and team mates.
    I was appreciated by the marketing team as well as my team for the application that I had built in a very short
    span of time.<br/>
    <span>Responsibilities : </span><br/>
    <ul>
        <li>
            Worked in an <span>agile environment,</span> and collaborated with the team
            regularly for code implementation.
        </li>
        <li>
            Prototyped, designed and developed a monitoring application
            using <span>flutter, dart and MQTT </span> enabling
            doctors to visualize and analyze patient vitals.
        </li>
        <li>
            Conceptualized and implemented real time tracking using
            <span> AWS Cloud services and Pragmatic State Management.</span>
        </li>
        <li>
            Deployed the app on multiple platforms like <span>Android, IOS, Web, and Windows.</span>
        </li>
    </ul>
</>

