import React from "react";

export const LightFactoryContent = () =>
    <>
    <p>
      <span>Duration : </span>2017<br/>
      <span>Technology Stack : </span>HTML, CSS, Javascript, jQuery,
      Bootstrap<br/>
      <span>Visit : </span>
      <a href="https://thelightfactory.herokuapp.com/"
         target="_blank"
         rel="noopener noreferrer"
      > The Light Factory
      </a>
    </p>
        <ul>
          <li>
            It all started with a couple of friends trying to help me
            showcase my passion for photography right after my
            bachelors.
          </li>
          <li>We discussed a lot and reached to a conclusion
            that we would have to go beyond Instagram and design a website
            dedicated for my Photography.
          </li>
          <li>
            The first version of this website used
            trivial iframes, HTML and CSS. But it was more than enough to
            get me hooked to User Interfaces
            and design.
          </li>
          <li>
            Soon after our project was over I got into Tata,
            where I worked as a developer and learnt more about Javascript
            and related frameworks. I revisited my then hosted website and
            realised that since there were multiple images loading and
            multiple sections of other sites loading inside the iframes, it
            was taking time to load.
          </li>
          <li>
            I developed this website. Unlike in the previous version this
            website was a Single Page Application and uses a minimalist
            design approach. This project is really close to my heart as it
            was my first individual project.
          </li>
        </ul>
    </>