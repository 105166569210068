import React from "react";
import "./footer-styles.css"

class FooterComponent extends React.Component {

    state = {
        fbUrl: "https://www.facebook.com/amit.madgi",
        instaUrl: "https://www.instagram.com/_a.mythh/",
        linkedInUrl: "https://www.linkedin.com/in/amit-madgi/"
    };

    openFb = () => {
        window.open(this.state.fbUrl, "_blank")
    };

    openInsta = () => {
        window.open(this.state.instaUrl, "_blank")
    };

    openLinkedIn = () => {
        window.open(this.state.linkedInUrl, "_blank")
    }

    render() {
        return (
            <div className="row">
                <div className="container-fluid footer" id="footer">
                    <div className="bg-overly"/>
                    <div className="container card-img-overlay">
                        <div className="row footer-social">
                            <div className="col-lg-12 text-center">
                                <ul className="navbar-nav d-inline-block mx-auto nav-fill social-icons">
                                    <li><a className="linkedin"
                                           href="https://www.linkedin.com/in/amit-madgi/"
                                           target='_blank'
                                           rel='noopener noreferrer'>
                                        <i className="fab fa-linkedin"/></a></li>
                                    <li><a className="facebook"
                                           href="https://www.facebook.com/amit.madgi"
                                           target='_blank'
                                           rel='noopener noreferrer'>
                                        <i className="fab fa-facebook-f fa-lg"/></a></li>
                                    <li><a className="twitter" href="#">
                                        <i className="fab fa-twitter"/></a>
                                    </li>
                                    <li><a className="dribbble"
                                           href="https://www.instagram.com/_a.mythh/"
                                           target='_blank'
                                           rel='noopener noreferrer'>
                                        <i className="fab fa-instagram"/></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-12">
                                <p> © 2020 My Portfolio | Design by <a
                                    href="https://www.facebook.com/amit.madgi"
                                    rel="noopener noreferrer"
                                    target="_blank">Amit Madgi</a> |
                                    All rights Reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default FooterComponent