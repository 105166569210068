import React from "react";
import "./skills.css";
import "../../App.css";
import logo from "../../logo.svg";
import jsLogo from "../../images/icons/js.png";
import htmlLogo from "../../images/icons/html.jpg";
import angularLogo from "../../images/icons/angular.png";
import javaLogo from "../../images/icons/java.png";
import pythonLogo from "../../images/icons/python-logo.svg";
import reduxLogo from "../../images/icons/redux.png";
import jQueryLogo from "../../images/icons/jQuery.png";
import nodejs from "../../images/icons/nodejs.svg";
import mongoDb from "../../images/icons/mongodb.png";
import flutter from "../../images/icons/flutter.png"
import dart from "../../images/icons/dart.png"
import cssLogo from "../../images/icons/css3.png";
import {Reveal} from "react-reveal";
import {connect} from "react-redux";
import ProgressLine from "../util/ProgressLine";
import Typewriter from "typewriter-effect";
import ScrollTrigger from 'react-scroll-trigger';

class SkillsComponent extends React.Component {

    state = {
        showTitle: false
    }

    onEnterViewport = () => this.setState({showTitle: true})

    render() {
        return (
            <ScrollTrigger onEnter={this.onEnterViewport}>
                <div className="row">
                    <div className="container-fluid skills" id="skills">
                        <div className="container pd-t-6">
                            {this.state.showTitle ? <Reveal effect="fadeInUp">
                                <h2 className="hd-48 text-center mb-5"><Typewriter
                                    onInit={(typewriter) => {
                                        typewriter
                                            .pauseFor(100)
                                            .typeString("Skills")
                                            .start();
                                    }}
                                /></h2>
                            </Reveal> : <></>}
                            <div className="row ml-0 mr-0">
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={logo} className="App-logo mt-3" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://reactjs.org/" rel="noopener noreferrer"
                                               target="_blank">React JS</a>
                                            <p>State and Lifecycle | Handling Events | React-Redux |
                                                Render Props | Routing | React-Reveal | more...
                                            </p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "85%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={angularLogo} className="logo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://angular.io/" rel="noopener noreferrer"
                                               target="_blank">Angular</a>
                                            <p>Directives | Controllers | Routing | Components &
                                                Templates | more...</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "75%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={javaLogo} className="jsLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://www.java.com/en/"
                                               rel="noopener noreferrer"
                                               target="_blank">JAVA</a>
                                            <p>OOP | Data Structures | Union Data Types | MVC
                                                Architecture | Adapters & Facades | more...</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "75%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={pythonLogo} className="pythonLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://www.python.org/"
                                               rel="noopener noreferrer"
                                               target="_blank">Python</a>
                                            <p>OOP | Data Structures | Flask | Web Scraping | Dictionaries | Parsers |
                                                more...</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "85%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={reduxLogo} className="jsLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://redux.js.org/" rel="noopener noreferrer"
                                               target="_blank">Redux</a>
                                            <p>connect() | Accessing Store | Providers | Hooks |
                                                React-Redux</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "75%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={nodejs} style={{"marginTop": "30px"}}
                                             className="nodeLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://nodejs.org/en/docs/"
                                               rel="noopener noreferrer"
                                               target="_blank">NodeJS</a>
                                            <p>Crypto | ECMAScript Modules | Session | Express |
                                                Mongoose | Cookies | HTTPS
                                            </p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "60%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={jQueryLogo} className="jqLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://jquery.com/" rel="noopener noreferrer"
                                               target="_blank">jQuery</a>
                                            <p>Effects | Events | Global Ajax Event Handlers | DOM
                                                Manipulation | more...</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "75%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={jsLogo} className="javaSLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://www.javascript.com/"
                                               rel="noopener noreferrer"
                                               target="_blank">Javascript</a>
                                            <p>Scoping | Arrow Functions | Extended Parameter Handling
                                                | Promises | more...</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "90%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={mongoDb} className="mt-3"
                                             style={{"height": "14vmin"}} alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://www.mongodb.com/"
                                               rel="noopener noreferrer"
                                               target="_blank">MongoDB</a>
                                            <p>Mongo Client | Mongoose Schemas | Embedded Schemas |
                                                Mongoose Model API | References | Field
                                                Configuration</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "55%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={flutter} style={{"marginTop": "30px"}}
                                             className="jsLogo pr-2" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://flutter.dev/" rel="noopener noreferrer"
                                               target="_blank">Flutter</a>
                                            <p>Pragmatic State Management | Providers | Bloc |
                                                JSON & Serialization | Cookies | Networking & http
                                            </p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "80%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={dart}
                                             style={{height: 60}}
                                             className="dartLogo d-none d-sm-block" alt="logo"/>
                                        <img src={dart}
                                             className="dartLogo-cell d-block d-sm-none" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://dart.dev/" rel="noopener noreferrer"
                                               target="_blank">Dart</a>
                                            <p>Command-line Tools | Static Analysis | Null Safety | Managing Source Code
                                            </p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "65%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={cssLogo} className="jsLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                                               rel="noopener noreferrer" target="_blank">CSS3</a>
                                            <p>Animations & Transitions | Attributes | Properties</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "75%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <img src={htmlLogo} className="jsLogo" alt="logo"/>
                                        <div className="media-body">
                                            <a className="mt-0" href="https://developer.mozilla.org/en-US/docs/Web/HTML"
                                               rel="noopener noreferrer" target="_blank">HTML5</a>
                                            <p>Attributes | Elements</p>
                                            <ProgressLine
                                                visualParts={[
                                                    {
                                                        percentage: "85%",
                                                        color: "#ffc000"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollTrigger>
        )
    }
}

const stateToPropertyMapper = (state) => {
    return {
        skills: state.skills
    }
};

export default connect(stateToPropertyMapper)(SkillsComponent)
