import React from "react";
import HeaderComponent from "../components/Header/HeaderComponent";
import "../styles/main.css";
import AboutComponent from "../components/About/AboutComponent";
import SkillsComponent from "../components/Skills/SkillsComponent";
import EducationComponent from "../components/Education/EducationComponent";
import RecommendationComponent
    from "../components/Recommendations/RecommendationComponent";
import ContactComponent from "../components/Contact/ContactComponent";
import FooterComponent from "../components/Footer/FooterComponent";
import {combineReducers, createStore} from "redux";
import skillsReducer from "../reducers/skillsReducer";
import headerReducer from "../reducers/headerReducer";
import {Provider} from "react-redux";
import ReactGa from "react-ga";
import Particles from "../components/Particles/Particles";
import AcademicProjectsComponent from "../components/Experience/AcademicProjectsComponent";

const rootReducer = combineReducers({
    skillsReducer: skillsReducer,
    headerReducer: headerReducer
});

export default class MainContainer extends React.Component {

    store = createStore(rootReducer);

    componentDidMount() {
        ReactGa.initialize("UA-166943637-1");
        //to report page view
        ReactGa.pageview('/')
    }

    render() {
        return (
            <div className="container-fluid overflow zoom">
                <Provider store={this.store}>
                    <HeaderComponent/>
                </Provider>
                <AboutComponent/>
                <Provider store={this.store}>
                    <SkillsComponent/>
                </Provider>
                <Particles/>
                <AcademicProjectsComponent/>
                <EducationComponent/>
                <RecommendationComponent/>
                <ContactComponent/>
                <FooterComponent/>
            </div>
        )
    }
}
