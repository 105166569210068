const state = {
  headerLinks: [
    {
      id: 1,
      title: "Home",
      className: "nav-link active",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "home",
    },
    {
      id: 2,
      title: "About",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "about",
    },
    {
      id: 3,
      title: "Skills",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "skills",
    },
    {
      id: 4,
      title: "Experience",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "work",
    },
    {
      id: 5,
      title: "Projects",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "academics",
    },
    {
      id: 6,
      title: "Education",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "education",
    },
    {
      id: 7,
      title: "Contact",
      className: "nav-link",
      spy: true,
      smooth: true,
      offset: 0,
      duration: 700,
      to: "contact",
    }
  ]
};

const headerReducer = () => {
  return state;
};

export default headerReducer;
